const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\AuftragsstuecklisteComponent.js";import React, { useState, } from "react";
import Parse from "parse";
import produce from "immer";
import config from "../config";

import { createArtikelTransaction, undoArtikelTransaction } from "./common";
import AuftragsstuecklistenPositionComponent from "./AuftragsstuecklistenPositionComponent";
import Loading from "../components/Loading";

import { Alert, Spin } from "antd";

import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import "antd/dist/antd.css";

function AuftragsstuecklisteComponent(props) {
  if (props.ShowNextButton()) {
    props.ShowNextButton(false);
  }
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [refreshing, setRefreshing] = React.useState(false);
  const [state, setState] = React.useState({
    data: { items: [], removals: [] },
    order: props.id,
    reference: searchParams.get("reference"),
    unit: params.unit,
  });

  React.useEffect(() => {
    update();
  }, []);
  const update = async () => {
    setRefreshing(true);
    let auftragQuery = new Parse.Query(config.references.table);
    auftragQuery.equalTo(config.references.field, state.reference);

    let auftrag = await auftragQuery.first();

    if (!auftrag) {
      setState(
        produce((draft) => {
          draft.info = {
            text: "Auftrag bzw. Stückliste nicht gefunden",
            description:
              "Der von ihnen gewählte Auftrag bzw. die enstsprechende(n) Stückliste(n) konnte(n) im System nicht gefunden werden",
            type: "error",
          };
        })
      );
      return;
    }

    let listenQuery = new Parse.Query("Auftragsstueckliste");

    listenQuery.equalTo("auftrag", auftrag);
    //listenQuery.equalTo("sqlRef", params.unit)

    let stcklisten = await listenQuery.find();
    let lists = [];
    let unDos = [];
    for (let list of stcklisten) {
      let positionQuery = new Parse.Query("AuftragsstuecklistenPosition");
      positionQuery.include("artikel");
      positionQuery.equalTo("auftragsstueckliste", list);
      let positionen = await positionQuery.find();
      let liste = list.toJSON();
      liste.positions = positionen.map((pos) => pos.toJSON());
      liste.removals = {};

      let openTransactionsQuery = new Parse.Query("open_transaction_artikel");
      openTransactionsQuery.equalTo("auftrag", auftrag);
      openTransactionsQuery.equalTo("auftragsstueckliste", list);
      openTransactionsQuery.include("artikel");
      openTransactionsQuery.include("user");
      openTransactionsQuery.equalTo("successful", true);

      let openTransactions = await openTransactionsQuery.find();

      for (const trans of openTransactions) {
        let removal = trans.toJSON();

        /*if (removal.undo) {
          unDos.push(removal.undo.objectId);
        }
        */
        let rList = liste.removals[removal.artikel.artikelnummer] || [];
        rList.push(removal);
        liste.removals[removal.artikel.artikelnummer] = rList;
      }

      /*
      Object.keys(liste.removals).forEach((key) => {
        liste.removals[key] = liste.removals[key].filter((removal) => {
          return unDos.indexOf(removal.objectId) === -1;
        });
      });
*/
      lists.push(liste);
    }
    setRefreshing(false);
    setState(
      produce((draft) => {
        draft.order = auftrag;
        draft.lists = lists;
        draft.filter = "";
        delete draft.unplannedItems;
        /*
        draft.data = {
          items: positionen.map((pos) => {
            return pos.toJSON();
          }),
          removals: openTransactions.map((pos) => {
            return pos.toJSON();
          }),
        };
        */
      })
    );
  };

  const getInfo = () => {
    if (state.info) {
      return (
        React.createElement(Alert, {
          style: { marginTop: "20px", marginBottom: "20px" },
          message: state.info.text,
          description: state.info.description,
          type: state.info.type,
          closable: true,
          onClose: () => {
            setState(
              produce((draft) => {
                draft.info = undefined;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
        )
      );
    }
    return "";
  };

  if (!state.lists) {
    return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}});
  }
  return (
    React.createElement('div', { id: "StockListComponent", __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
      , React.createElement('div', { style: { width: "0px", height: "0px", overflow: "hidden" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
        , React.createElement('input', { id: "imeInputField", __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}})
      )
      , getInfo()
      , React.createElement(Spin, { spinning: refreshing, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
        , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 156}})
      )
      , React.createElement(Stocklist, {
        Lists: state.lists,
        SetExtras: props.SetExtras,
        Update: async (data) => {
          for (let removal of data.removals) {
            await createArtikelTransaction(removal);
          }
          for (let restock of data.restocked) {
            await undoArtikelTransaction(restock, restock.menge);
          }

          update();
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
      )
    )
  );
}
export default AuftragsstuecklisteComponent;

const Stocklist = (props) => {
  const { unit } = useParams();
  const [filter, setFilter] = useState({ show: false, value: "" });
  console.log(props);
  const listGroups = props.Lists.reduce((group, value) => {
    let groupIndex = value.hierarchyParent;
    if (!groupIndex || groupIndex === "0") {
      groupIndex = "root";
    }
    if (!group[groupIndex]) {
      group[groupIndex] = [];
    }
    // Grouping
    group[groupIndex].push(value);

    return group;
  }, {});

  const lists = [];
  const removals = [];

  //Create hierarchy of StckLists
  props.Lists.forEach((list) => {
    Object.values(list.removals).forEach((removal) => {
      removals.push(removal);
    });

    if (!list.hierarchyParent || list.hierarchyParent === "0") {
      lists.push({
        ...list,
        children: listGroups[list.BDENummer],
      });
    }
  });

  const list = lists.find((list) => list.objectId === unit);

  const update = (data) => {
    props.Update(data);
    setFilter({ show: false, value: "" });
  };

  return (
    React.createElement('div', { className: "fullHeight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
      , React.createElement(AuftragsstuecklistenPositionComponent, {
        SetExtras: props.SetExtras,
        List: list,
        Update: update,
        Filter: filter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
      )
    )
  );
};

const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\forms\\FormSelect.js";import React, { useState } from "react";
import Parse from "parse";
import QrReader from "react-qr-scanner";
import { isIOS, isSafari } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import config from "../config";

import {
  UserOutlined,

  LogoutOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Menu,
  Modal,
  Dropdown,
  Button,
  PageHeader,
  Select,
  Divider,
  AutoComplete,
  Result,
  Drawer,
  List,
  Avatar,
} from "antd";
import FormList from "./FormList";
import "antd/dist/antd.css";

import Loading from "../components/Loading";
import { Icon } from "@opendash/icons";
const { Option } = Select;

function FormSelect(props) {
  const [state, setState] = useState({
    modalVisible: false,
    delay: 500,
    result: "No result",
    stillLoading: true,
    reference: localStorage.getItem("openchecklistref") || null,
    unitFilter: ["all"],
  });
  const navigate = useNavigate();

  const [drawerMenu, setDrawerMenu] = React.useState({
    open: false,
    items: config.menuItems,
  });

  const getReferences = async () => {
    if (!config.references) return; //No Server Side Refs

    const query = new Parse.Query(config.references.table);
    query.limit(9999999);
    let references = await query.find();
    setState(
      produce((draft) => {
        draft.references = references.map((ref) => {
          let val = "";
          for (let part of config.references.label) {
            if (val !== "") {
              val += " - ";
            }
            val += ref.get(part);
          }
          return { value: val, key: ref.get(config.references.field) };
        });
      })
    );
  };

  const emtpyReference = (
    React.createElement(Result, {
      title: "Bitte wählen Sie einen Auftrag aus"     ,
      extra: 
        React.createElement(Button, {
          type: "primary",
          key: "console",
          onClick: () => {
            updateReferenceModal(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        , "Auftrag festlegen"

        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
    )
  );
  const getFormsByUnit = async () => {
    setState(
      produce((draft) => {
        draft.stillLoading = true;
      })
    );

    //Nehme die aktuelle Auftragsnummer, die der Nutzer in das Feld eingetragen hat (state.reference)
    //Wenn eine eingetragen ist, gucke ob der aktuelle Wert mit dieser matched
    let x = null;
    if (!state.reference) {
      setState(
        produce((draft) => {
          draft.stillLoading = false;
        })
      );
      return emtpyReference;
    }

    const pUnits = await config.units.get(state.reference);
    const units = {};
    let first = true;

    for (let unit of pUnits) {
      let forms = await config.forms.get(unit, state.reference);

      forms = forms.map((form) => {
        let cForm = form;
        cForm.description = Object.keys(cForm.form)
          .filter((key) => {
            return key.indexOf("step") === 0;
          })
          .sort((a, b) => {
            return (
              parseInt(a.replace("step", "")) - parseInt(b.replace("step", ""))
            );
          })
          .map((key) => {
            return cForm.form[key].title;
          })
          .join(", ");

        return cForm;
      });

      units[unit.objectId] = unit;
      units[unit.objectId].forms = forms;
    }
    setState(
      produce((draft) => {
        draft.units = units;
        draft.stillLoading = false;
      })
    );
  };

  React.useEffect(() => {
    getReferences();
  }, []);
  React.useEffect(() => {
    getFormsByUnit();
  }, [state.reference]);

  if (state.stillLoading) return React.createElement(Loading, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 153}});

  const updateReferenceModal = async (modalVisible) => {
    setState(
      produce((draft) => {
        draft.modalVisible = modalVisible;
      })
    );
  };

  const handleScan = (data) => {
    if (data) {
      setState(
        produce((draft) => {
          draft.reference = data;
          localStorage.setItem("openchecklistref", data);
          draft.modalVisible = false;
        })
      );
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const menu = (
    React.createElement(Menu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
      , React.createElement(Menu.Item, {
        icon: React.createElement(LogoutOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 182}} ),
        onClick: () => {
          if (Parse.User.current()) {
            Parse.User.logOut();
            localStorage.clear();
            window.location = "/login";
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
      , "Logout"

      )
    )
  );
  if (!Parse.User.current()) {
    window.location = "/login";
    return;
  }
  const DropdownMenu = () => {
    return (
      React.createElement(Dropdown, { key: "more", overlay: menu, trigger: ["click"], __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
        , React.createElement(Button, {
          style: {
            textAlign: "right",
          },
          icon: 
            React.createElement(UserOutlined, {
              style: {
                fontSize: 24,
                verticalAlign: "top",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
        
          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}, Parse.User.current().get("username"))
        )
      )
    );
  };

  const qrCODEScanner = () => {
    if (config.qrEnabled && !isIOS && !isSafari && state.modalVisible) {
      return (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 224}}
          , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}})

          , React.createElement('div', { style: { height: "320px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 227}}
            , React.createElement(QrReader, {
              delay: state.delay,
              style: { height: 240, width: "100%" },
              onError: handleError,
              onScan: handleScan,
              showViewFinder: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
            )
          )
          , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 236}})
        )
      );
    } else {
      return React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 240}});
    }
  };

  const UnitForms = (props) => {
    const options = Object.keys(state.units).map((key) => {
      return (
        React.createElement(Option, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}
          , state.units[key][config.units.field]
        )
      );
    });

    const getChildren = (key, alreadyAdded) => {
      return Object.values(state.units)
        .filter((unit) => {
          return (
            unit[config.units.source].indexOf(key[[config.units.source]]) ==
              0 &&
            unit[config.units.source].split(".").length ===
              key[config.units.source].split(".").length + 1
          );
        })
        .map((child) => {
          alreadyAdded.push(child[config.units.source]);
          const children = getChildren(child[config.units.field], alreadyAdded);
          return {
            value: child.objectId,
            label: child[config.units.field],
            children: children.length > 0 ? children : void 0,
          };
        });
    };

    return (
      React.createElement('div', { key: "UnitList", __self: this, __source: {fileName: _jsxFileName, lineNumber: 275}}
        , React.createElement('div', {
          style: {
            backgroundColor: "#fff",
            marginBottom: "8px",
            paddingTop: "8px",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 276}}
        
          , React.createElement(Select, {
            style: { width: "100%" },
            defaultValue: ["all"],
            value: state.unitFilter,
            placeholder: "Bitte auswählen" ,
            onChange: (value, text) => {
              setState(
                produce((draft) => {
                  draft.unitFilter = value;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 283}}
          
            , React.createElement(Option, { value: "all", key: "all", __self: this, __source: {fileName: _jsxFileName, lineNumber: 296}}, "Alle anzeigen"

            )
            , Object.keys(state.units).map((unitKey) => {
              return (
                React.createElement(Option, { value: unitKey, key: unitKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 301}}
                  , state.units[unitKey][config.units.field]
                )
              );
            })
          )
        )
        , Object.keys(state.units)
          .filter((filterKey) => {
            if (state.unitFilter.indexOf("all") === 0) return true;
            return filterKey.toLowerCase() === state.unitFilter.toLowerCase();
          })
          .map((key) => {
            return (
              React.createElement(FormList, { Unit: state.units[key], Reference: state.reference, __self: this, __source: {fileName: _jsxFileName, lineNumber: 315}} )
            );
          })
        , React.createElement('div', {
          style: {
            backgroundColor: "#fff",
            textAlign: "center",
            padding: "5px",
            paddingTop: "15px",
            color: "gray",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 318}}
        
          , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 327}})
          , Object.keys(state.units).length > 1 ||
          Object.keys(state.units).length === 0
            ? "Insgesamt " +
              Object.keys(state.units).length +
              " Einheiten vorhanden"
            : "Insgesamt " +
              Object.keys(state.units).length +
              " Einheit vorhanden"
        )
      )
    );
  };

  return (
    React.createElement('div', { style: { backgroundColor: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 342}}
      , React.createElement(Drawer, {
        visible: drawerMenu.open,
        placement: "left",
        width: "75%",
        onClose: () => {
          console.log("onCancel");
          setDrawerMenu(
            produce(drawerMenu, (draft) => {
              draft.open = false;
            })
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 343}}
      
        , React.createElement('img', {
          src: 
            config.logo ||
            "https://openinc.de/wp-content/uploads/2018/04/cropped-Bildschirmfoto-2018-04-25-um-11.22.23.png"
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 356}}
        )
        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 362}})
        , React.createElement(List, {
          itemLayout: "horizontal",
          dataSource: drawerMenu.items,
          renderItem: (item) => (
            React.createElement(List.Item, {
              className: "sideMenuItem",
              onClick: () => {
                navigate(item.target);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 367}}
            
              , React.createElement(List.Item.Meta, {
                title: item.label,
                description: 
                  item.description || "Keine Informationen verfügbar"
                ,
                avatar: React.createElement(Avatar, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 378}}, item.label.charAt(0)), __self: this, __source: {fileName: _jsxFileName, lineNumber: 373}}
              )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 363}}
        )
      )

      , React.createElement('table', { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}
        , React.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}
          , React.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 387}}
            , React.createElement('td', { style: { textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 388}}
              , React.createElement(PageHeader, {
                title: "BDE",
                backIcon: React.createElement(Icon, { icon: "fa:bars", __self: this, __source: {fileName: _jsxFileName, lineNumber: 391}}),
                avatar: { src: "opensystems.png" },
                style: { background: "#fff", paddingBottom: "5px" },
                extra: [],
                onBack: () => {
                  console.log("click");
                  setDrawerMenu(
                    produce(drawerMenu, (draft) => {
                      draft.open = !draft.open;
                    })
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 389}}
              )
            )
            , React.createElement('td', { style: { textAlign: "right", paddingRight: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 405}}
              , React.createElement(DropdownMenu, {
                style: { textAlign: "right", fontSize: 24 },
                key: "more", __self: this, __source: {fileName: _jsxFileName, lineNumber: 406}}
              )
            )
          )
          , React.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 412}}
            , React.createElement('td', { colSpan: "2", style: { paddingBottom: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 413}}
              , React.createElement('div', {
                className: "headerpara",
                style: {
                  marginTop: "3px",
                  width: "100%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 414}}
              
                , React.createElement(Button, {
                  type: "primary",
                  key: "ref_key_btn",
                  style: {
                    textAlign: "center",
                    border: "1px dotted",
                    borderColor: "darkgrey",
                    fontSize: "12pt",
                    width: "100%",
                  },
                  onClick: () => updateReferenceModal(true),
                  icon: 
                    React.createElement(EditOutlined, {
                      style: { fontSize: 24, verticalAlign: "top" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 435}}
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 423}}
                , "Auftrag: "

                  , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 441}}
                    , !state.reference || state.reference === 0
                      ? "nicht vorhanden"
                      : state.reference
                  )
                )
              )
            )
          )
        )
      )

      , state.reference && state.units ? React.createElement(UnitForms, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 453}} ) : emtpyReference

      , React.createElement(Divider, { style: { margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 455}})

      , React.createElement(Modal, {
        title: "Auftragsnummer",
        style: { top: 20 },
        visible: state.modalVisible,
        onOk: () => {
          updateReferenceModal(false);
        },
        onCancel: () => updateReferenceModal(false),
        footer: [
          React.createElement(Button, {
            key: "back",
            onClick: () => {
              clearBrowserHistory();
              setState(
                produce((draft) => {
                  delete draft.reference;
                  delete draft.refCandidate;
                  localStorage.removeItem("openchecklistref");
                  draft.modalVisible = false;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 466}}
          , "Ohne Auftragsbezug"

          ),
          React.createElement(Button, {
            key: "submit",
            type: "primary",
            onClick: () => {
              let x;
              clearBrowserHistory();
              if (state.references) {
                x = state.references.find((ref) => {
                  return ref.value === state.refCandidate;
                });
              }
              setState(
                produce((draft) => {
                  if (x) {
                    draft.reference = x.key;
                  } else {
                    draft.reference = state.refCandidate;
                  }
                  delete draft.refCandidate;
                  localStorage.setItem("openchecklistref", draft.reference);
                  draft.modalVisible = false;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 482}}
          , "Übernehmen"

          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 457}}
      
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 511}}, "Bitte geben Sie eine Auftragsnummer an:"     )
        /* <Input
          name="referenceinput"
          value={props.Reference}
          placeholder="A19876567"
          onChange={({ target: { value } }) => props.ChangeReference(value)}
       /> */

        , React.createElement(AutoComplete, {
          name: "referenceselect",
          options: state.references,
          allowClear: true,
          dropdownClassName: "reference_input",
          placeholder: "Auftragsnummer eingeben oder auswählen..."   ,
          style: { width: "100%" },
          filterOption: (inputValue, option) => {
            return (
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            );
          },
          onChange: (e) => {
            const val = e;
            setState(
              produce((draft) => {
                draft.refCandidate = val;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 519}}
        )

        , qrCODEScanner()
      )
    )
  );
}
export default FormSelect;
export async function clearBrowserHistory() {
  console.log(history);
  /*let backlen = history.length;
  let location = window.location.href;
  history.go(-(backlen - 1));
  setTimeout(() => {
    window.location.href = location;
  }, 5000);*/
}

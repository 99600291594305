const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\custom_wehn\\AuftragsstuecklistenPositionComponent.js"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { } from "react";
import Parse from "parse";
import produce from "immer";

import { getListItem } from "./common";
import ArtikelBuchungDrawerComponent from "./ArtikelBuchungDrawerComponent";

import {

  List,
  Alert,

  Button,
  Input,
  Drawer,
  Col,
  Row,
  Select,
  Spin,
  Result,

} from "antd";

import { Icon } from "@opendash/icons";

import "antd/dist/antd.css";
import { useNavigate } from "react-router";

export default function AuftragsstuecklistenPositionComponent(props) {
  console.log(props);
  const list = props.List;
  const [lagerOrte, setLagerOrte] = React.useState({});
  const currentList = list.objectId;
  const { Option } = Select;
  const [refreshing, setRefreshing] = React.useState(false);
  const [state, setState] = React.useState({
    showFilter: props.Filter.show,
    filter: props.Filter.value,
  });
  const navigate = useNavigate();
  const [selection, setSelection] = React.useState(-1);
  const inputid = "input_field_filter" + parseInt(Math.random() * 1000);
  const selectItem = (item) => {
    //O-Teil
    if (!item.artikel) return;

    //Position gesperrt
    if (item.gesperrt) return;
    /*
    setState(
      produce((draft) => {
        draft.selectedItem = { ...item };
        draft.showFilter = false;
      })
    );*/
    navigate(`/entnahme/AuftragsstuecklistenPosition/${item.objectId}`);
  };
  const getLagerOrte = async () => {
    const positions =
      selection < 0 ? list.positions : list.children[selection].positions;
    if ((_optionalChain([positions, 'optionalAccess', _ => _.length]) || 0) === 0) return;
    console.log("LagerPositionen", list);
    let artikelArray = positions.map((position) => {
      return position.artikel
        ? new Parse.Object("Artikel", {
            objectId: position.artikel.objectId,
          })
        : void 0;
    });
    artikelArray = artikelArray.filter((artikelCandidate) => {
      return !!artikelCandidate;
    });

    let lagerQuery = new Parse.Query("ArtikelEinlagerung");
    lagerQuery.containedIn("artikel", artikelArray);
    // lagerQuery.include("lager");
    lagerQuery.include("lagerort");
    let einlagerungen = await lagerQuery.find();

    let bestandQuery = new Parse.Query("ArtikelBestand");
    bestandQuery.include("lager");
    bestandQuery.containedIn("artikel", artikelArray);
    let bestaende = await bestandQuery.find();
    let result = {};

    for (const bestand of bestaende) {
      const artikelId = _optionalChain([bestand, 'access', _2 => _2.get, 'call', _3 => _3("artikel"), 'optionalAccess', _4 => _4.id]);
      const lagerNr = parseInt(_optionalChain([bestand, 'access', _5 => _5.get, 'call', _6 => _6("lager"), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("sqlRef")]));

      result[artikelId] = {
        lagerNr,
        lager: bestand.get("lager").get("sqlRef"),
        lagerName: bestand.get("lager").get("bezeichnung"),
      };
    }
    for (const einlagerung of einlagerungen) {
      const artikelId = einlagerung.get("artikel").id;
      const lagerNr = parseFloat(
        JSON.parse(_optionalChain([einlagerung, 'access', _9 => _9.get, 'call', _10 => _10("lagerort"), 'optionalAccess', _11 => _11.get, 'call', _12 => _12("sqlRef")]) || "[0,0]").join(
          "."
        )
      );

      result[artikelId] = {
        lagerNr,
        lagerName: einlagerung.get("lager").get("bezeichnung"),
        lagerOrt:
          _optionalChain([einlagerung, 'access', _13 => _13.get, 'call', _14 => _14("lagerort"), 'optionalAccess', _15 => _15.get, 'call', _16 => _16("lagerort")]) || "Kein Lagerort",
        lager: einlagerung.get("lager").get("sqlRef"),
      };
    }
    setLagerOrte(result);
  };

  React.useEffect(() => {
    getLagerOrte();
  }, [selection, list]);

  React.useEffect(() => {
    props.SetExtras([
      React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
        , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
          , React.createElement(Button, {
            style: { width: "80px" },
            type: "primary",
            icon: React.createElement(Icon, { icon: "fa:search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}} ),
            key: "stockremoval_search",
            onClick: () => {
              setState(
                produce((draft) => {
                  draft.filter = "";
                  draft.showFilter = true;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
          )
        )
      ),
    ]);
  }, []);
  React.useEffect(() => {
    setState(
      produce((draft) => {
        draft.showFilter = props.Filter.show;
        draft.filter = props.Filter.value;
      })
    );
  }, [props.Filter]);
  const subscribe = async () => {
    let livequery = new Parse.Query("AuftragsstuecklistenPosition");
    let aufList = new Parse.Object("Auftragsstueckliste");
    aufList.id = props.List.objectId;
    livequery.equalTo("auftragsstueckliste", aufList);
    let subscription = await livequery.subscribe();
    const liveUdpate = (object) => {
      update({ removals: [], restocked: [] });
      setRefreshing(true);
    };
    subscription.on("update", liveUdpate);
    subscription.on("create", liveUdpate);
  };
  React.useEffect(() => {
    setRefreshing(false);
  }, [props.List]);
  React.useEffect(() => {
    subscribe();

    let keydownListener = (e) => {
      if (
        e.target.tagName.toUpperCase() === "INPUT" ||
        (e.key.length > 1 && e.key.toLowerCase() !== "unidentified")
      ) {
        return;
      } else {
        setState(
          produce((draft) => {
            //draft.filter = e.key.toLowerCase() === "unidentified" ? "" : e.key;
            draft.showFilter = true;
          })
        );

        e.preventDefault();
        let el = document.getElementById("input_filter_field");
        if (el) el.focus();
      }
    };
    document.addEventListener("keydown", keydownListener);

    return () => {
      document.removeEventListener("keydown", keydownListener);
    };
  }, []);

  const update = (dataupdate) => {
    props.Update(dataupdate);
  };
  const search = () => {
    const mainQuery = new Parse.Query("Artikel").contains(
      "suchindex",
      state.filter.toLowerCase()
    );
    mainQuery.find().then((res) => {
      let items = res.map((item) => {
        let pItem = {};
        pItem["bezeichnung"] = item.get("bezeichnung");
        pItem["auftragsstueckliste"] = { objectId: currentList };
        pItem["position"] = "ungeplant";
        pItem["artikelnr"] = item.get("artikelnummer");
        pItem["menge"] = 0;
        pItem["auftrag"] = list.auftrag;
        pItem["fertig"] = item.get("fertig");
        pItem["auftragsposition"] = list.position;
        pItem["artikel"] = {
          type: "__pointer",
          className: "Artikel",
          objectId: item.id,
        };
        return pItem;
      });
      setState(
        produce((draft) => {
          draft.unplannedItems = items;
          draft.filter = "";
          draft.showFilter = false;
        })
      );
    });
  };
  const getFilter = () => {
    if (state.showFilter && selection == -1) {
      return (
        React.createElement(Alert, {
          style: {
            position: "fixed",
            left: "0px",
            right: "0px",
            top: "0px",
            zIndex: "9999",
          },
          message: "Aktiver Filter" ,
          description: 
            React.createElement('div', { id: inputid, __self: this, __source: {fileName: _jsxFileName, lineNumber: 242}}
              , React.createElement(Input, {
                id: "input_filter_field",
                value: state.filter,
                autoFocus: true,
                onChange: (e) => {
                  const val = e.target.value;
                  setState(
                    produce((draft) => {
                      draft.filter = val;
                    })
                  );
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 243}}
              )
            )
          ,
          type: "info",
          closable: true,
          onClose: () => {
            setState(
              produce((draft) => {
                draft.filter = "";
                draft.showFilter = false;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
        )
      );
    }
    return "";
  };

  const sortItems = (item1, item2) => {
    const doneFlag1 =
      item1.fertig &&
      !(
        (item1.mengeAngefordertNichtBestellt > 0 ||
          item1.mengeBestelltWehn > 0) &&
        item1.mengeIst == 0 &&
        item1.mengeSoll == 0
      );
    const mailFlag1 =
      item1.mengeAngefordertNichtBestellt > 0 || item1.mengeBestelltWehn > 0;
    const gesperrtFlag1 =
      !(
        item1.mengeAngefordertNichtBestellt > 0 || item1.mengeBestelltWehn > 0
      ) &&
      item1.gesperrt &&
      !item1.fertig;

    const doneFlag2 =
      item2.fertig &&
      !(
        (item2.mengeAngefordertNichtBestellt > 0 ||
          item2.mengeBestelltWehn > 0) &&
        item2.mengeIst == 0 &&
        item2.mengeSoll == 0
      );
    const mailFlag2 =
      item2.mengeAngefordertNichtBestellt > 0 || item2.mengeBestelltWehn > 0;
    const gesperrtFlag2 =
      !(
        item2.mengeAngefordertNichtBestellt > 0 || item2.mengeBestelltWehn > 0
      ) &&
      item2.gesperrt &&
      !item2.fertig;

    const begonnenFlag1 = !item1.fertig && item1.mengeIst > 0;
    const begonnenFlag2 = !item2.fertig && item2.mengeIst > 0;

    const oteilFlag1 = !item1.artikel;
    const oteilFlag2 = !item2.artikel;

    const keinBestandFlag1 =
      !doneFlag1 &&
      !begonnenFlag1 &&
      !_optionalChain([lagerOrte, 'access', _17 => _17[_optionalChain([item1, 'access', _18 => _18.artikel, 'optionalAccess', _19 => _19.objectId])], 'optionalAccess', _20 => _20.lagerNr]);
    const keinBestandFlag2 =
      !doneFlag2 &&
      !begonnenFlag2 &&
      !_optionalChain([lagerOrte, 'access', _21 => _21[_optionalChain([item2, 'access', _22 => _22.artikel, 'optionalAccess', _23 => _23.objectId])], 'optionalAccess', _24 => _24.lagerNr]);

    if (gesperrtFlag1 !== gesperrtFlag2) {
      if (gesperrtFlag1) return 1;
      return -1;
    }
    if (doneFlag1 !== doneFlag2) {
      if (doneFlag1) return 1;
      return -1;
    }
    if (oteilFlag1 !== oteilFlag2) {
      if (oteilFlag1) return 1;
      return -1;
    }
    if (mailFlag1 !== mailFlag2) {
      if (mailFlag1) return 1;
      return -1;
    }
    if (keinBestandFlag1 !== keinBestandFlag2) {
      if (keinBestandFlag1) return 1;
      return -1;
    }
    if (begonnenFlag1 !== begonnenFlag2) {
      if (begonnenFlag1) return 1;
      return -1;
    }

    const lager1 = _optionalChain([lagerOrte, 'access', _25 => _25[_optionalChain([item1, 'optionalAccess', _26 => _26.artikel, 'optionalAccess', _27 => _27.objectId])], 'optionalAccess', _28 => _28.lagerNr]) || 0;
    const lager2 = _optionalChain([lagerOrte, 'access', _29 => _29[_optionalChain([item2, 'optionalAccess', _30 => _30.artikel, 'optionalAccess', _31 => _31.objectId])], 'optionalAccess', _32 => _32.lagerNr]) || 0;
    //TODO if lager1 - lager2 ==0 -> Artikelnr
    return lager1 - lager2;
  };

  return (
    React.createElement('div', { style: props.style, className: "wehn-entnahme-stuecklisten", __self: this, __source: {fileName: _jsxFileName, lineNumber: 356}}
      , getFilter()
      , 
        React.createElement(Spin, { spinning: refreshing, __self: this, __source: {fileName: _jsxFileName, lineNumber: 359}}
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 360}})
        )
      
      , React.createElement(Select, {
        style: { width: "100%", marginBottom: "10px" },
        className: "stcklistselector",
        defaultValue: selection,
        onSelect: (val) => {
          setSelection(val);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 363}}
      
        , React.createElement(Option, { value: -1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 371}}, "Aktuelle Ebene: " + list.bezeichnung)

        , list.children
          ? list.children
              .sort((a, b) => a.BDENummer - b.BDENummer)
              .map((child, index) => {
                return (
                  React.createElement(Option, { style: { marginLeft: "10px" }, value: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 378}}
                    , child.bezeichnung
                  )
                );
              })
          : ""
      )
      , selection !== -1 && list.children && list.children[selection] ? (
        React.createElement(AuftragsstuecklistenPositionComponent, {
          SetExtras: props.SetExtras,
          Filter: { show: state.showFilter, value: state.filter },
          style: { marginLeft: "10px" },
          List: list.children[selection],
          Stocks: lagerOrte,
          Update: update, __self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}
        )
      ) : (
        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 395}}
          , React.createElement(List, {
            style: { background: "#fff", padding: "12px" },
            itemLayout: "vertical",
            locale: {
              emptyText: (
                React.createElement(React.Fragment, null
                  , React.createElement(Button, {
                    type: "primary",
                    key: "console",
                    onClick: () => {
                      if (state.showFilter) {
                        search();
                      } else {
                        setState(
                          produce((draft) => {
                            draft.showFilter = true;
                            draft.filter = "";
                          })
                        );
                      }
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 402}}
                  , "Artikel suchen und entnehmen"

                  )
                  , React.createElement(Result, { title: "Artikel in aktueller Stückliste nicht gefunden!"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 420}} )
                )
              ),
            },
            dataSource: list.positions
              .filter((item) => {
                return (
                  JSON.stringify(item)
                    .toLowerCase()
                    .indexOf((state.filter || "").toLowerCase()) != -1
                );
              })
              .sort((a, b) => {
                return sortItems(a, b);
              }),
            renderItem: (item) => {
              return getListItem(item, lagerOrte, selectItem);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 396}}
          )
          , React.createElement(ArtikelBuchungDrawerComponent, {
            selectedItem: state.selectedItem,
            removals: list.removals[_optionalChain([state, 'access', _33 => _33.selectedItem, 'optionalAccess', _34 => _34.artikelnr])] || [],
            update: (data) => {
              setState(
                produce((draft) => {
                  draft.selectedItem = undefined;
                })
              );
              update(data);
            },
            onClose: () => {
              setState(
                produce((draft) => {
                  draft.selectedItem = undefined;
                })
              );
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 439}}
          )
          , React.createElement(Drawer, {
            placement: "bottom",
            title: "Ungeplante Entnahme",
            visible: state.unplannedItems,
            closable: "true",
            onClose: () => {
              setState(
                produce((draft) => {
                  delete draft.unplannedItems;
                })
              );
            },
            height: "80%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 458}}
          
            , React.createElement(List, {
              itemLayout: "vertical",
              dataSource: state.unplannedItems,
              renderItem: (item) => (
                React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 476}}
                  , React.createElement(List.Item.Meta, {
                    title: "" + item.artikelnr,
                    description: item.bezeichnung, __self: this, __source: {fileName: _jsxFileName, lineNumber: 477}}
                  )
                  , React.createElement(Button, {
                    onClick: () => {
                      setState(
                        produce((draft) => {
                          delete draft.unplannedItems;
                          //draft.selectedItem = item;
                        })
                      );
                      console.log(list, list.auftragPosition.objectId);

                      navigate(
                        `/entnahme/Artikel/${item.artikel.objectId}?auftrag=${list.auftrag.objectId}&aufpos=${list.auftragPosition.objectId}&stckliste=${currentList}`
                      );
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 481}}
                  , "entnehmen"

                  )
                )
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 472}}
            )
          )
        )
      )
    )
  );
}

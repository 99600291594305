const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.CHECK\\open.CHECKLIST\\src\\js\\components\\Login.js";import React from "react";
import { Form, Input, Button, notification } from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import Parse from "parse";
import Icon from "@ant-design/icons";
import "antd/dist/antd.css";

import { useNavigate } from "react-router";

const FormItem = Form.Item;
const logo = require("../logo512.png");
function Login(props) {
  const navigate = useNavigate();
  const openNotification = (error, title, msg) => {
    notification.open({
      message: title,
      description: msg,
      icon: error ? (
        React.createElement(CloseOutlined, { style: { color: "#e63541" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
      ) : (
        React.createElement(InfoCircleOutlined, { style: { color: "#108ee9" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
      ),
    });
  };
  const onFinish = async (e) => {
    console.log(escape);
    try {
      await Parse.User.logIn(e.userName.toLowerCase(), e.password);
      openNotification(
        false,
        "Anmelden erfolgreich",
        "Sie werden in kürze umgeleitet..."
      );
      setTimeout(() => {
        navigate("/select");
      }, 2000);
    } catch (error) {
      console.error(error);
      openNotification(
        true,
        "Login nicht möglich",
        "Kombination aus Email und Passwort ist falsch."
      );
      console.error("Kombination aus Email und Passwort sind falsch.");
    }
  };
  return (
    React.createElement('div', { style: { background: "#fff" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      , React.createElement('div', {
        style: {
          paddingTop: "30px",
          paddingBottom: "30px",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      
        , React.createElement('img', { height: "250", alt: "app-icon", src: logo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}})
      )
      , React.createElement(Form, { onFinish: onFinish, className: "login-form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        , React.createElement(FormItem, { name: "userName", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          , React.createElement(Input, {
            prefix: React.createElement(Icon, { type: "user", style: { fontSize: 13 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} ),
            placeholder: "Username", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
          )
        )

        , React.createElement(FormItem, { name: "password", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
          , React.createElement(Input, {
            prefix: React.createElement(Icon, { type: "lock", style: { fontSize: 13 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} ),
            type: "password",
            placeholder: "Password", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          )
        )

        , React.createElement(FormItem, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
          , React.createElement(Button, {
            type: "primary",
            htmlType: "submit",
            className: "login-form-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
          , "Log in"

          )
        )
      )
    )
  );
}
export default Login;
